@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  ul,
  ol {
    list-style-image: none;
    list-style-position: inside;
    list-style-type: disc;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
  color: var(--color);
}

.theme-accessible div {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;
}

.theme-accessible {
  --bg-color: rgb(0, 0, 0);
  --bg-color-2: rgb(20, 20, 20);
  --bg-color-3: rgb(50, 50, 50);
  --bg-color-4: rgb(80, 80, 80);
  --color: rgb(255, 255, 255);
  --color-complete: rgb(0, 200, 0);
  --color-error: rgb(255, 50, 50);
  --color-gray: rgb(180, 180, 180);
  --color-incomplete: rgb(230, 200, 20);
  --level-block: rgb(0, 0, 0);
  --level-block-border: rgb(255, 193, 7);
  --level-end: rgb(60, 166, 229);
  --level-grid: rgb(255, 255, 255);
  --level-grid-text: rgb(0, 0, 0);
  --level-grid-used: rgb(247, 127, 171);
  --level-hole: rgb(7, 187, 202);
  --level-hole-border: rgb(0, 77, 64);
  --level-player: rgb(246, 97, 96);
  --level-player-extra: rgb(175, 255, 117);
  --level-player-text: rgb(0, 0, 0);
  --level-wall: rgb(0, 0, 0);
}

.theme-classic {
  --bg-color: rgb(51, 51, 51);
  --bg-color-2: rgb(70, 70, 70);
  --bg-color-3: rgb(100, 100, 100);
  --bg-color-4: rgb(150, 150, 150);
  --color: rgb(255, 255, 255);
  --color-complete: rgb(0, 200, 0);
  --color-error: rgb(255, 50, 50);
  --color-gray: rgb(180, 180, 180);
  --color-incomplete: rgb(230, 200, 20);
  --level-block: rgb(174, 101, 100);
  --level-block-border: rgb(215, 155, 154);
  --level-end: rgb(255, 255, 255);
  --level-grid: rgb(100, 118, 50);
  --level-grid-text: rgb(0, 0, 0);
  --level-grid-used: rgb(126, 152, 26);
  --level-hole: rgb(51, 51, 51);
  --level-hole-border: rgb(100, 100, 100);
  --level-player: rgb(40, 40, 40);
  --level-player-extra: rgb(199, 44, 44);
  --level-player-text: rgb(255, 255, 255);
  --level-wall: rgb(0, 0, 0);
}

.theme-monkey {
  --bg-color: rgb(40, 13, 6);
  --bg-color-2: rgb(86, 34, 11);
  --bg-color-3: rgb(90, 67, 55);
  --bg-color-4: rgb(96, 48, 16);
  --color: rgb(255, 255, 255);
  --color-complete: rgb(141, 239, 141);
  --color-error: rgb(178, 34, 34);
  --color-gray: rgb(128, 128, 128);
  --color-incomplete: rgb(218, 165, 32);
  --level-block: rgb(47, 79, 79);
  --level-block-border: rgb(145, 165, 116);
  --level-end: rgb(255, 248, 144);
  --level-grid: rgb(46, 139, 87);
  --level-grid-text: rgb(0, 0, 0);
  --level-grid-used: rgb(0, 128, 0);
  --level-hole: rgb(51, 51, 51);
  --level-hole-border: rgb(100, 100, 100);
  --level-player: rgb(233, 161, 136);
  --level-player-extra: rgb(160, 45, 45);
  --level-player-text: rgb(0, 0, 0);
  --level-wall: rgb(112, 67, 54);
}

.theme-monkey-4 {
  animation: rotateMonkey 2s infinite linear;
}

@keyframes rotateMonkey {
  0% {
    transform: rotate(-10deg);
  }

  50% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(-10deg);
  }
}

.theme-dark {
  --bg-color: rgb(0, 0, 0);
  --bg-color-2: rgb(20, 20, 20);
  --bg-color-3: rgb(50, 50, 50);
  --bg-color-4: rgb(80, 80, 80);
  --color: rgb(255, 255, 255);
  --color-complete: rgb(0, 200, 0);
  --color-error: rgb(255, 50, 50);
  --color-gray: rgb(180, 180, 180);
  --color-incomplete: rgb(230, 200, 20);
  --level-block: rgb(0, 0, 0);
  --level-block-border: rgb(200, 139, 97);
  --level-end: rgb(255, 255, 255);
  --level-grid: rgb(120, 120, 120);
  --level-grid-text: rgb(0, 0, 0);
  --level-grid-used: rgb(90, 90, 90);
  --level-hole: rgb(30, 30, 30);
  --level-hole-border: rgb(60, 60, 60);
  --level-player: rgb(255, 165, 0);
  --level-player-extra: rgb(255, 55, 55);
  --level-player-text: rgb(0, 0, 0);
  --level-wall: rgb(0, 0, 0);
}

.theme-light {
  --bg-color: rgb(255, 255, 255);
  --bg-color-2: rgb(240, 240, 240);
  --bg-color-3: rgb(220, 220, 220);
  --bg-color-4: rgb(150, 150, 150);
  --color: rgb(0, 0, 0);
  --color-complete: rgb(0, 218, 0);
  --color-error: rgb(255, 50, 50);
  --color-gray: rgb(100, 100, 100);
  --color-incomplete: rgb(224, 192, 0);
  --level-block: rgb(181, 164, 110);
  --level-block-border: rgb(216, 210, 156);
  --level-end: rgb(255, 181, 181);
  --level-grid: rgb(153, 204, 254);
  --level-grid-text: rgb(0, 0, 0);
  --level-grid-used: rgb(172, 214, 255);
  --level-hole: rgb(98, 98, 98);
  --level-hole-border: rgb(165, 165, 165);
  --level-player: rgb(64, 254, 64);
  --level-player-extra: rgb(244, 86, 86);
  --level-player-text: rgb(0, 0, 0);
  --level-wall: rgb(76, 100, 127);
}

.theme-modern {
  --bg-color: rgb(38, 38, 38);
  --bg-color-2: rgb(50, 50, 50);
  --bg-color-3: rgb(80, 80, 80);
  --bg-color-4: rgb(130, 130, 130);
  --color: rgb(255, 255, 255);
  --color-complete: rgb(0, 200, 0);
  --color-error: rgb(255, 50, 50);
  --color-gray: rgb(180, 180, 180);
  --color-incomplete: rgb(230, 200, 20);
  --level-block: rgb(0, 0, 0);
  --level-block-border: rgb(183, 119, 57);
  --level-end: rgb(229, 229, 229);
  --level-grid: rgb(14, 168, 117);
  --level-grid-text: rgb(0, 0, 0);
  --level-grid-used: rgb(4, 110, 80);
  --level-hole: rgb(65, 65, 65);
  --level-hole-border: rgb(106, 106, 106);
  --level-player: rgb(244, 114, 182);
  --level-player-extra: rgb(240, 80, 80);
  --level-player-text: rgb(0, 0, 0);
  --level-wall: rgb(38, 38, 38);
}

.theme-boss {
  --bg-color: rgb(22, 21, 21);
  --bg-color-2: rgb(50, 50, 50);
  --bg-color-3: rgb(80, 80, 80);
  --bg-color-4: rgb(130, 130, 130);
  --color: rgb(255, 255, 255);
  --color-complete: rgb(0, 200, 0);
  --color-error: rgb(255, 50, 50);
  --color-gray: rgb(180, 180, 180);
  --color-incomplete: rgb(230, 200, 20);
  --level-block: rgb(0, 0, 0);
  --level-block-border: rgb(152, 95, 37);
  --level-end: rgb(198, 195, 195);
  --level-grid: rgb(206, 9, 9);
  --level-grid-text: rgb(0, 0, 0);
  --level-grid-used: rgb(121, 38, 38);
  --level-hole: rgb(65, 65, 65);
  --level-hole-border: rgb(106, 106, 106);
  --level-player: rgb(117, 102, 110);
  --level-player-extra: rgb(244, 86, 86);
  --level-player-text: rgb(0, 0, 0);
  --level-wall: rgb(38, 38, 38);
}

.theme-winter {
  --bg-color: rgb(22, 21, 21);
  --bg-color-2: rgb(50, 50, 50);
  --bg-color-3: rgb(80, 80, 80);
  --bg-color-4: rgb(130, 130, 130);
  --color: rgb(255, 255, 255);
  --color-complete: rgb(0, 200, 0);
  --color-error: rgb(255, 50, 50);
  --color-gray: rgb(180, 180, 180);
  --color-incomplete: rgb(230, 200, 20);
  --level-block: rgb(0, 0, 0);
  --level-block-border: rgb(155, 91, 70);
  --level-end: rgb(229, 229, 229);
  --level-grid: rgb(21, 103, 117);
  --level-grid-text: rgb(0, 0, 0);
  --level-grid-used: rgb(76, 147, 164);
  --level-hole: rgb(65, 65, 65);
  --level-hole-border: rgb(106, 106, 106);
  --level-player: rgb(244, 114, 182);
  --level-player-extra: rgb(244, 144, 144);
  --level-player-text: rgb(0, 0, 0);
  --level-wall: rgb(38, 38, 38);
}

.theme-halloween {
  --bg-color: rgb(22, 21, 21);
  --bg-color-2: rgb(50, 50, 50);
  --bg-color-3: rgb(80, 80, 80);
  --bg-color-4: rgb(130, 130, 130);
  --color: rgb(255, 255, 255);
  --color-complete: rgb(0, 200, 0);
  --color-error: rgb(255, 125, 50);
  --color-gray: rgb(180, 180, 180);
  --color-incomplete: rgb(230, 200, 20);
  --level-block: rgb(0, 0, 0);
  --level-block-border: rgb(155, 91, 70);
  --level-end: rgb(133, 226, 31);
  --level-grid: rgb(247, 95, 28);
  --level-grid-text: rgb(0, 0, 0);
  --level-grid-used: rgb(255, 154, 0);
  --level-hole: rgb(65, 65, 65);
  --level-hole-border: rgb(106, 106, 106);
  --level-player: rgb(136, 30, 228);
  --level-player-extra: rgb(178, 0, 111);
  --level-player-text: rgb(0, 0, 0);
  --level-wall: rgb(38, 38, 38);
}

[data-theme-dark="true"] {
  color-scheme: dark;
}

#arrow,
#arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

#tooltip {
  z-index: 100;
}

#tooltip[data-popper-placement^='top']>#arrow {
  bottom: -4px;
}

#tooltip[data-popper-placement^='bottom']>#arrow {
  top: -4px;
}

#tooltip[data-popper-placement^='left']>#arrow {
  right: -4px;
}

#tooltip[data-popper-placement^='right']>#arrow {
  left: -4px;
}

.bg-button {
  background-color: var(--bg-color-3);
}

.bg-button:hover {
  background-color: var(--bg-color-4);
}

.audio-bar-button {
  background-color: var(--bg-color-2);
}

.audio-bar-button:enabled:hover {
  background-color: var(--bg-color-4);
}

.glow {
  animation: glow 1s ease-in-out infinite alternate;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {

  /* glow purplish text-shadow */
  from {
    text-shadow: 0 0 10px rgb(153, 76, 76), 0 0 20px rgb(45, 29, 127), 0 0 30px rgb(104, 186, 21), 0 0 40px rgb(59, 115, 82), 0 0 50px rgb(166, 8, 39), 0 0 60px #fff, 0 0 70px #fff;
  }

  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
  }
}

.flashBackground {
  /* flash once */
  animation: flashBackground 2s ease-in-out alternate;
  -webkit-animation: flashBackground 2s ease-in-out alternate;
}

@keyframes flashBackground {
  from {
    background-color: var(--bg-color-3);
  }

  to {
    background-color: inherit;
  }
}

@keyframes blurToClear {
  0% {
    filter: blur(20px);
  }

  100% {
    filter: blur(0px);
  }
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

@keyframes fadeIn {
  from {
    opacity: 0;

  }

  to {
    opacity: 1;

  }
}

.fadeOut {
  animation-name: fadeOut;
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.bounce {
  animation: bounce 1s infinite;
}

@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }

  50% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
}

/* nprogress */
/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #29d;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1.0;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;

  top: 0%;
  left: 50%;
  transform: translate(-50%, 75%);
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.tab {
  background-color: var(--bg-color-1);
}

.tab:hover {
  background-color: var(--bg-color-4);
}

.tab-active {
  background-color: var(--bg-color-3);
}

.tab-active:hover {
  background-color: var(--bg-color-4);
}

.subscription-plan-button {
  background-color: var(--bg-color-2);
}

.subscription-plan-button:hover {
  background-color: var(--bg-color-3);
}

.editor-selected::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 3px solid rgb(230, 50, 50);
  pointer-events: none;
  z-index: 30;
}

.pagination-button:enabled:hover {
  background-color: var(--bg-color-3);
}

.hover-bg-2:hover {
  background-color: var(--bg-color-2);
}

.hover-bg-3:hover {
  background-color: var(--bg-color-3);
}

.hover-bg-4:hover {
  background-color: var(--bg-color-4);
}

.bg-1 {
  background-color: var(--bg-color);
}

.bg-2 {
  background-color: var(--bg-color-2);
}

.bg-3 {
  background-color: var(--bg-color-3);
}

.bg-4 {
  background-color: var(--bg-color-4);
}

.border-color-2 {
  border-color: var(--bg-color-2);
}

.border-color-3 {
  border-color: var(--bg-color-3);
}

.border-color-4 {
  border-color: var(--bg-color-4);
}

.hover-color:hover {
  color: var(--color);
}

.gray {
  color: var(--color-gray);
}

/**
 * ScreenSize.XL is when the desktop view displays on the level page
 * assume anything under this could be a mobile device
 */
@media (min-width: 1280px) {
  .select-card-button {
    display: none;
  }

  .select-card:hover .select-card-button {
    display: block;
  }
}

/**
 * prevent the user from selecting the entire screen on mobile (headless portal)
 */
#headlessui-portal-root>[data-headlessui-portal]:first-child {
  user-select: none;
}

/**
 * prevent the user from selecting the entire screen on mobile (headless dialog)
 */
#headlessui-portal-root>[data-headlessui-portal]:nth-child(2)>div>div>div:first-child {
  user-select: none;
}

input,
textarea {
  background-color: var(--bg-color);
  border: 1px solid var(--bg-color-3);
  border-radius: 0.25rem;
  padding: 0.5rem 0.75rem;
  transition-duration: 150ms;
  transition-property: border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

input::placeholder,
textarea::placeholder {
  color: var(--color-gray);
}

input:focus,
textarea:focus {
  border-color: rgb(59 130 246);
  outline: none;
}

.animate-fade-in {
  animation: fadeIn 0.3s ease-out forwards;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

@keyframes pulse {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

.animate-pulse {
  animation: pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}